<template>
  <v-row class="d-inherit">
    <v-col>
      <el-table
        :data="filteredReport33.finalResult"
        border
        size="small"
        v-loading="reportsStore.table_loading"
        class="h-100"
      >
        <el-table-column label="Korporativ kartalar bo'yicha operatsiyalar">
          <el-table-column
            align="center"
            width="130"
            prop="oper_date"
            label="Sana"
          >
            <template slot-scope="scope">
              {{ $dayjs(scope.row.oper_date).format('DD-MM-YYYY') }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            width="180"
            prop="operation_type_description"
            label="operatsiya turi"
          />

          <el-table-column
            align="center"
            width="180"
            prop="counteragent_name"
            label="Korrespondent"
          />

          <el-table-column
            align="center"
            width="180"
            prop="bought_product_service"
            label="Sotib olingan tovar nomi"
          />

          <el-table-column align="center" width="180" label="Avtomashina">
            <el-table-column
              align="center"
              width="180"
              prop="truck_model_brand"
              label="Turi"
            />
            <el-table-column
              align="center"
              width="180"
              prop="truck_reg_number"
              label="Davlat raqami"
            />
          </el-table-column>

          <el-table-column
            align="center"
            width="180"
            prop="amount"
            label="Summa"
          >
            <template slot-scope="scope">
              {{ scope.row.amount | toRoundFloat }}
            </template>
          </el-table-column>

          <el-table-column align="center" width="180" prop="" label="Chiqim">
            <el-table-column
              align="center"
              width="180"
              prop="total_with_nds"
              label="NDSsiz summasi"
            >
              <template slot-scope="scope">
                {{ scope.row.total | toRoundFloat }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              width="180"
              prop="nds_percent"
              label="NDS foizi"
            />
            <el-table-column
              align="center"
              width="180"
              prop="nds_summa"
              label="NDS summasi"
            >
              <template slot-scope="scope">
                {{ scope.row.nds_summa | toRoundFloat }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              width="180"
              prop="total_with_nds"
              label="Jami chiqim NDS bilan"
            >
              <template slot-scope="scope">
                {{ scope.row.total_with_nds | toRoundFloat }}
              </template>
            </el-table-column>
          </el-table-column>

          <!-- <el-table-column
            align="center"
            width="180"
            prop="remain_amount"
            label="Qoldiq"
          > -->
            <!-- <template slot-scope="scope">
              {{ scope.row.remain_amount | toRoundFloat }}
            </template>
          </el-table-column> -->
        </el-table-column>
      </el-table>
    </v-col>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from 'vuex'

import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { toRoundFloat } from '@/utils'

export default {
  computed: {
    ...mapState(['reportsStore']),
    ...mapGetters(['filteredReport33']),

    isLoading() {
      return this.$store.state.requests.isLoading
    }
  },

  data() {
    return {
      loading: false
    }
  },

  filters: { toRoundFloat },

  mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Korporativ Kartalar hisoboti' }])
    })
    // console.log('Table 33 mounted')
  },

  beforeDestroy() {
    this.$store.dispatch(SET_BREADCRUMB, [])
  },
}
</script>
